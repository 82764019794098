.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.line {
  width: 100;
  height: 3rem;
  overflow: hidden;
  padding: 0;

}
.container {
  position: relative;
  text-align: center;
  color: white;
}

/* Bottom left text */
.bottom-left {
  position: absolute;
  bottom: 8px;
  left: 16px;
}

/* Top left text */
.top-left {
  position: absolute;
  top: 8px;
  left: 16px;
}

/* Top right text */
.top-right {
  position: absolute;
  top: 8px;
  right: 16px;
}

/* Bottom right text */
.bottom-right {
  position: absolute;
  bottom: 8px;
  right: 16px;
}

/* Centered text */
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.landing-section{
  height: 80vh;
  background-image: url("./Assets/RGHouse/drone.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.landing{
  height: 100vh;
  background-image: url("./Assets/RGHouse/drone.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.revealLeft {
  display: block;
  animation: revealLeft 1s cubic-bezier(0.77, 0, 0.175, 1);
}
.revealRight {
  display: block;
  animation: revealRight 1s cubic-bezier(0.77, 0, 0.175, 1);
}
.revealTop {
  display: block;
  animation: revealTop 1s cubic-bezier(0.77, 0, 0.175, 1) ;
}
.revealBottom {
  display: block;
  animation: revealBottom 1s cubic-bezier(0.77, 0, 0.175, 1);
}
.fade { animation: fadeIn 3s; }

@keyframes revealTop {
  0% {
    transform: translate(0,-100%);
  }
  100% {
    transform: translate(0,0);
  }
}
@keyframes revealBottom {
  0% {
    transform: translate(0,100%);
  }
  100% {
    transform: translate(0,0);
  }
}

@keyframes revealLeft {
  0% {
    transform: translate(-100%,0);
  }
  100% {
    transform: translate(0,0);
  }
}
@keyframes revealRight {
  0% {
    transform: translate(100%,0);
  }
  100% {
    transform: translate(0,0);
  }
}
@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
.parent {
  display: grid;
  grid-template-columns: 1fr;
}

.child {
  grid-row-start: 1;
  grid-column-start: 1;
}
div.sticky {
  position: sticky;
  top: 90px;


  font-size: 20px;
}
